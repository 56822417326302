<template>
  <div>
    <v-snackbar absolute top :timeout="3000" v-model="snackbar">{{
      snackbarText
    }}</v-snackbar>

    <v-overlay class="loading-center" :value="saving">
      <lottie
        :options="defaultOptions"
        :height="150"
        :width="150"
        v-on:animCreated="handleAnimation"
      />
    </v-overlay>

    <v-card>
      <v-card-title class="grid-close">
        <span class="headline">Enlaces de {{ city.name }}</span>
        <v-btn
          style="right: 10px; top: 10px"
          icon
          color="primary"
          fab
          absolute
          @click="$emit('cancel')"
        >
          <v-icon dark>fa-times</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text
        style="max-height: 65vh; min-height: 65vh; overflow-y: scroll"
      >
        <v-container fluid>
          <v-row>
            <v-col cols="12" md="7" sm="7">
              <v-text-field
                v-model="search"
                clearable
                rounded
                flat
                filled
                hide-details
                :label="`Buscar enlace`"
                prepend-inner-icon="fa-search"
                class="d-none d-md-flex"
              />
            </v-col>
            <v-col cols="12" md="5" sm="5">
              <v-row justify="end">
                <v-btn
                  color="primary"
                  class="ma-2 white--text mr-5"
                  @click="
                    modalSort = true;
                    randomKey = Math.random();
                  "
                  :loading="loading"
                >
                  <v-icon right dark class="mr-3">fas fa-bars</v-icon>
                  Ordenar
                </v-btn>

                <v-btn
                  color="primary"
                  class="ma-2 white--text"
                  @click="newLink"
                  :loading="loading"
                >
                  <v-icon right dark class="mr-3">fas fa-plus</v-icon>
                  Crear nuevo enlace
                </v-btn>
              </v-row>
            </v-col>
          </v-row>

          <v-data-table
            :headers="headers"
            :items="links"
            :search="search"
            :items-per-page="5"
            fixed-header
            :loading="loading"
            :sort-by="['position']"
            class="mt-5"
            :sort-desc="[false, true]"
            item-key="id"
            :footer-props="{
              itemsPerPageOptions: [5],
            }"
          >
            <!-- slot active -->
            <template v-slot:item.active="{ item }">
              <div style="display: flex ; justify-content: center;!important">
                <v-switch
                  @change="
                    switchControlChanged(
                      { active: item.active },
                      item['.key'],
                      item
                    )
                  "
                  v-model="item.active"
                ></v-switch>
              </div>
            </template>
            <template v-slot:item.options="{ item }">
              <v-btn @click="editLink(item)" small color="primary"
                >Editar</v-btn
              >
              <v-btn
                class="ml-5 white--text"
                @click="
                  itemToDelete = item;
                  modalDelete = true;
                "
                small
                color="red darken-4"
                >Eliminar</v-btn
              >
            </template>

            <template v-slot:loading>
              <div class="ma-5" style="position: relative">
                <div
                  style="
                    position: absolute;
                    z-index: 999;
                    width: 100%;
                    height: 100%;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                  "
                >
                  <lottie
                    :options="defaultOptions"
                    :height="300"
                    :width="400"
                    v-on:animCreated="handleAnimation"
                  />
                  <p
                    :class="
                      $vuetify.theme.dark
                        ? 'subtitle-1 primary--text'
                        : 'subtitle-1 black--text'
                    "
                  >
                    Cargando Data
                  </p>
                </div>
                <v-skeleton-loader
                  ref="skeleton"
                  type="table-tbody"
                  class="mx-auto d-none d-md-block"
                ></v-skeleton-loader>
              </div>
            </template>
          </v-data-table>
        </v-container>
      </v-card-text>
    </v-card>

    <v-dialog v-model="modalSort" max-width="500px">
      <sort
        :key="`sort-${randomKey}`"
        @cancel="modalSort = false"
        :cityKey="city['.key']"
        :links="links"
      ></sort>
    </v-dialog>

    <v-dialog persistent v-model="modalAddLink" max-width="600px">
      <add-link
        :position="links.length + 1"
        @success="handleCreate"
        :selectedLink="selectedLink"
        :key="randomKey"
        @cancel="modalAddLink = false"
        :city="city"
      ></add-link>
    </v-dialog>

    <v-dialog max-width="400" v-model="modalDelete" v-if="itemToDelete">
      <v-card>
        <v-card-title class="headline"
          >Confirmar acción
          <v-btn
            style="right: 10px; top: 10px"
            icon
            color="primary"
            fab
            absolute
            @click="modalDelete = false"
          >
            <v-icon dark>fa-times</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text>
          ¿Está seguro que desea eliminar
          <span class="font-weight-bold">{{ itemToDelete.name }}</span
          >?
        </v-card-text>

        <v-card-actions style="justify-content: center">
          <v-btn
            color="red darken-4"
            width="90%"
            style="border-radius: 50px"
            class="mb-3"
            dark
            @click="confirmDelete"
          >
            Eliminar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { db, fb } from "@/firebase";
import Lottie from "../../../../components/Lottie";
import * as animationData from "../../../../assets/ochoColor.json";
import { Parser } from "json2csv";
import moment from "moment-timezone";
import "moment/locale/es";
moment.locale("es");
import { mapActions, mapState } from "vuex";
import addLink from "./add-link"; // add and edit link
import sort from "./sort";

export default {
  name: "sections",
  props: ["city"],
  components: {
    lottie: Lottie,
    addLink,
    sort,
  },
  data() {
    return {
      animationSpeed: 1.1,
      defaultOptions: {
        animationData: animationData.default,
        loop: true,
        autoplay: true,
      },

      snackbar: false,
      snackbarText: "",
      saving: false,
      loading: true,
      selectedLink: null,
      modalSort: false,
      modalAddLink: false,
      modalDelete: false,
      itemToDelete: null,
      search: null,
      links: [],
      randomKey: 0,
      headers: [
        { text: "Posición", value: "position" },
        { text: "Nombre", value: "name" },
        { text: "Icono", value: "icon" },
        { text: "Url", value: "url" },
        { text: "Activo", value: "active", align: "center", justify: "center" },
        { value: "options", align: "end", width: "350px" },
      ],
    };
  },
  async mounted() {
    this.$binding(
      "links",
      db
        .collection(`cities/${this.city[".key"]}/links`)
        .where("deleted", "==", false)
    ).then((links) => {
      this.loading = false;
    });
  },

  methods: {
    handleAnimation(anim) {
      this.anim = anim;
      anim.setSpeed(this.animationSpeed);
    },
    handleCreate() {
      this.modalAddLink = false;
      this.snackbarText = "Información actualizada correctamente.";
      this.snackbar = true;
    },

    handleUpdate() {
      this.modalEditSection = false;
      this.snackbarText = "Información actualizada correctamente.";
      this.snackbar = true;
    },

    confirmDelete() {
      if (this.itemToDelete) {
        db.collection(`cities/${this.city[".key"]}/links`)
          .doc(this.itemToDelete[".key"])
          .update({
            deleted: true,
            active: false,
            deletedAt: new Date(),
            deletedBy: this.$store.state.user[".key"],
          })
          .then(() => {
            this.modalDelete = false;
            this.snackbar = true;
            this.snackbarText = "Enlace eliminado";
          })
          .catch((error) => {
            this.snackbarText =
              "Ocurrió un error inesperado, inténtelo nuevamente.";
            this.snackbar = true;
          });
      }
    },

    switchControlChanged(data, linkId, link) {
      if (data && linkId) {
        this.saving = true;

        db.collection(`cities/${this.city[".key"]}/links`)
          .doc(linkId)
          .update({
            active: data.active,
          })
          .then((ref) => {
            this.saving = false;
            this.snackbarText = "Información actualizada correctamente.";
            this.snackbar = true;
          })
          .catch((err) => {
            this.saving = false;
            this.snackbarText =
              "Ocurrió un error inesperado, inténtelo nuevamente.";
            this.snackbar = true;

            link.active = !link.active;
          });
      }
    },
    newLink() {
      this.randomKey = Math.random();
      this.selectedLink = null;
      this.modalAddLink = true;
    },

    editLink(link) {
      this.randomKey = Math.random();
      this.selectedLink = link;
      this.modalAddLink = true;
    },
  },
};
</script>
