var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',[_c('v-snackbar',{attrs:{"absolute":"","top":"","timeout":3000},model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_vm._v(_vm._s(_vm.snackbarText))]),_c('v-overlay',{staticClass:"loading-center",attrs:{"value":_vm.saving}},[_c('lottie',{attrs:{"options":_vm.defaultOptions,"height":150,"width":150},on:{"animCreated":_vm.handleAnimation}})],1),_c('v-card-title',{staticClass:"grid-close"},[_c('span',{staticClass:"headline"},[_vm._v("Configurar sección "+_vm._s(_vm.section.name))]),_c('v-btn',{staticStyle:{"right":"10px","top":"10px"},attrs:{"icon":"","color":"primary","fab":"","absolute":""},on:{"click":function($event){return _vm.$emit('cancel')}}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v("fa-times")])],1)],1),_c('v-divider'),_c('v-card-text',{staticStyle:{"max-height":"65vh","min-height":"65vh","overflow-y":"scroll"}},[_c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"7","sm":"7"}}),_c('v-col',{attrs:{"cols":"12","md":"5","sm":"5"}},[_c('v-row',{attrs:{"justify":"end"}},[_c('v-btn',{staticClass:"ma-2 white--text mr-5",attrs:{"color":"primary","loading":_vm.loading},on:{"click":function($event){_vm.modalSort = true;
                _vm.randomKey = Math.random();}}},[_c('v-icon',{staticClass:"mr-3",attrs:{"right":"","dark":""}},[_vm._v("fas fa-bars")]),_vm._v(" Ordenar ")],1),_c('v-btn',{staticClass:"ma-2 white--text",attrs:{"color":"primary","loading":_vm.loading},on:{"click":_vm.newItem}},[_c('v-icon',{staticClass:"mr-3",attrs:{"right":"","dark":""}},[_vm._v("fas fa-plus")]),_vm._v(" Agregar item ")],1)],1)],1)],1),_c('v-data-table',{staticClass:"mt-5",attrs:{"headers":_vm.headers,"items":_vm.localSection.target,"search":_vm.search,"items-per-page":5,"fixed-header":"","loading":_vm.loading,"sort-by":['position'],"sort-desc":[false, true],"item-key":"id","footer-props":{
          itemsPerPageOptions: [5],
        }},scopedSlots:_vm._u([{key:`item.active`,fn:function({ item }){return [_c('div',{staticStyle:{"display":"flex","justify-content":"center"}},[_c('v-switch',{on:{"change":function($event){return _vm.switchControlChanged({ active: item.active }, item)}},model:{value:(item.active),callback:function ($$v) {_vm.$set(item, "active", $$v)},expression:"item.active"}})],1)]}},{key:`item.cover`,fn:function({ item }){return [_c('div',{staticStyle:{"display":"flex","justify-content":"center"}},[_c('v-img',{staticStyle:{"border-radius":"5px"},attrs:{"src":item.cover.original,"lazy-src":item.cover.loading,"width":"100px","height":"50px"}})],1)]}},{key:`item.isBusinessActive`,fn:function({ item }){return [_c('div',{staticStyle:{"display":"flex","justify-content":"center"}},[_c('v-switch',{on:{"change":function($event){return _vm.switchControlChanged(
                  { isBusinessActive: item.isBusinessActive },
                  item
                )}},model:{value:(item.isBusinessActive),callback:function ($$v) {_vm.$set(item, "isBusinessActive", $$v)},expression:"item.isBusinessActive"}})],1)]}},{key:`item.isComingSoon`,fn:function({ item }){return [_c('div',{staticStyle:{"display":"flex","justify-content":"center"}},[_c('v-switch',{on:{"change":function($event){return _vm.switchControlChanged(
                  { isComingSoon: item.isComingSoon },
                  item
                )}},model:{value:(item.isComingSoon),callback:function ($$v) {_vm.$set(item, "isComingSoon", $$v)},expression:"item.isComingSoon"}})],1)]}},{key:`item.closed`,fn:function({ item }){return [_c('div',{staticStyle:{"display":"flex","justify-content":"center"}},[_c('v-switch',{on:{"change":function($event){return _vm.switchControlChanged({ closed: item.closed }, item)}},model:{value:(item.closed),callback:function ($$v) {_vm.$set(item, "closed", $$v)},expression:"item.closed"}})],1)]}},{key:`item.isForceClosed`,fn:function({ item }){return [_c('div',{staticStyle:{"display":"flex","justify-content":"center"}},[_c('v-switch',{on:{"change":function($event){return _vm.switchControlChanged(
                  { isForceClosed: item.isForceClosed },
                  item
                )}},model:{value:(item.isForceClosed),callback:function ($$v) {_vm.$set(item, "isForceClosed", $$v)},expression:"item.isForceClosed"}})],1)]}},{key:`item.isDiscount`,fn:function({ item }){return [(item.type == 'business')?_c('div',{staticStyle:{"display":"flex","justify-content":"center"}},[_c('v-switch',{on:{"change":function($event){return _vm.switchControlChanged({ isDiscount: item.isDiscount }, item)}},model:{value:(item.isDiscount),callback:function ($$v) {_vm.$set(item, "isDiscount", $$v)},expression:"item.isDiscount"}})],1):_vm._e()]}},{key:`item.type`,fn:function({ item }){return [_c('v-chip',{attrs:{"outlined":""}},[_vm._v(" "+_vm._s(_vm._f("filterType")(item.type))+" ")])]}},{key:`item.discountMax`,fn:function({ item }){return [_vm._v(" "+_vm._s(item.type == "url" ? "" : item.type == "business" ? item.discountMax : "")+" ")]}},{key:`item.options`,fn:function({ item }){return [_c('v-btn',{attrs:{"small":"","color":"primary"},on:{"click":function($event){return _vm.editSection(item)}}},[_vm._v("Editar")]),_c('v-btn',{staticClass:"ml-5 white--text",staticStyle:{"border-radius":"10px"},attrs:{"small":"","color":"red darken-4"},on:{"click":function($event){_vm.itemToDelete = item;
              _vm.modalDelete = true;}}},[_vm._v("Eliminar")])]}},{key:"loading",fn:function(){return [_c('div',{staticClass:"ma-5",staticStyle:{"position":"relative"}},[_c('div',{staticStyle:{"position":"absolute","z-index":"999","width":"100%","height":"100%","display":"flex","flex-direction":"column","justify-content":"center","align-items":"center"}},[_c('lottie',{attrs:{"options":_vm.defaultOptions,"height":300,"width":400},on:{"animCreated":_vm.handleAnimation}}),_c('p',{class:_vm.$vuetify.theme.dark
                    ? 'subtitle-1 primary--text'
                    : 'subtitle-1 black--text'},[_vm._v(" Cargando Data ")])],1),_c('v-skeleton-loader',{ref:"skeleton",staticClass:"mx-auto d-none d-md-block",attrs:{"type":"table-tbody"}})],1)]},proxy:true}],null,true)})],1)],1),_c('v-dialog',{attrs:{"max-width":"500px"},model:{value:(_vm.modalSort),callback:function ($$v) {_vm.modalSort=$$v},expression:"modalSort"}},[_c('sort',{key:`sort-${_vm.randomKey}`,attrs:{"cityKey":_vm.city['.key'],"section":_vm.localSection},on:{"cancel":function($event){_vm.modalSort = false}}})],1),_c('v-dialog',{attrs:{"persistent":"","max-width":"800px"},model:{value:(_vm.modalAddItem),callback:function ($$v) {_vm.modalAddItem=$$v},expression:"modalAddItem"}},[_c('add-item',{key:_vm.randomKey,attrs:{"selectedItem":_vm.selectedItem,"section":_vm.localSection,"city":_vm.city},on:{"updated":_vm.handleUpdate,"success":_vm.handleCreate,"cancel":function($event){_vm.modalAddItem = false}}})],1),(_vm.itemToDelete)?_c('v-dialog',{attrs:{"max-width":"400"},model:{value:(_vm.modalDelete),callback:function ($$v) {_vm.modalDelete=$$v},expression:"modalDelete"}},[_c('v-card',[_c('v-card-title',{staticClass:"headline"},[_vm._v("Confirmar acción "),_c('v-btn',{staticStyle:{"right":"10px","top":"10px"},attrs:{"icon":"","color":"primary","fab":"","absolute":""},on:{"click":function($event){_vm.modalDelete = false}}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v("fa-times")])],1)],1),_c('v-card-text',[_vm._v(" ¿Está seguro que desea eliminar "),_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.itemToDelete.shortName))]),_vm._v("? ")]),_c('v-card-actions',{staticStyle:{"justify-content":"center"}},[_c('v-btn',{staticClass:"mb-3",staticStyle:{"border-radius":"50px"},attrs:{"color":"red darken-4","width":"90%","dark":""},on:{"click":_vm.confirmDelete}},[_vm._v(" Eliminar ")])],1)],1)],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }