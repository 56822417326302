<template>


    <v-card>
          <v-snackbar absolute top :timeout="3000" v-model="snackbar">{{
      snackbarText
    }}</v-snackbar>

    <v-overlay class="loading-center" :value="saving">
      <lottie
        :options="defaultOptions"
   :height="150"
        :width="150"
        v-on:animCreated="handleAnimation"
      />
    </v-overlay>

      <v-card-title class="grid-close">
        <span class="headline">Secciones de Ovive para {{ city.name }} </span>
        <v-btn
          style="right: 10px; top: 10px"
          icon
          color="primary"
          fab
          absolute
          @click="$emit('cancel')"
        >
          <v-icon dark>fa-times</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text
        style="max-height: 65vh; min-height: 65vh; overflow-y: scroll"
      >
        <v-container fluid>
          <v-row>
            <v-col cols="12" md="7" sm="7">
              <v-text-field
                v-model="search"
                clearable
                rounded
                flat
                filled
                hide-details
                :label="`Buscar sección`"
                prepend-inner-icon="fa-search"
                class="d-none d-md-flex"
              />
            </v-col>
            <v-col cols="12" md="5" sm="5">
              <v-row justify="end">
                <v-btn
                  color="primary"
                  class="ma-2 white--text mr-5"
                  @click="
                    modalSort = true;
                    randomKey = Math.random();
                  "
                  :loading="loading"
                >
                  <v-icon right dark class="mr-3">fas fa-bars</v-icon>
                  Ordenar
                </v-btn>

                <v-btn
                  color="primary"
                  class="ma-2 white--text"
                  @click="newSection"
                  :loading="loading"
                >
                  <v-icon right dark class="mr-3">fas fa-plus</v-icon>
                  Crear nueva sección
                </v-btn>
              </v-row>
            </v-col>
          </v-row>

          <v-data-table
            :headers="headers"
            :items="sections"
            :search="search"
            :items-per-page="5"
            fixed-header
            :loading="loading"
            :sort-by="['position']"
            class="mt-5"
            :sort-desc="[false, true]"
            item-key="id"
            :footer-props="{
              itemsPerPageOptions: [10],
            }"
          >
            <!-- slot active -->
            <template v-slot:[`item.active`]="{ item }">
              <div style="display: flex ; justify-content: center;!important">
                <v-switch
                  @change="
                    switchControlChanged(
                      { active: item.active },
                      item['.key'],
                      item
                    )
                  "
                  v-model="item.active"
                ></v-switch>
              </div>
            </template>

            <template v-slot:[`item.options`]="{ item }">
              <v-btn @click="editSection(item)" small color="primary"
                >Editar</v-btn
              >
              <v-btn
                @click="confSection(item)"
                small
                color="primary"
                class="ml-5"
                >Configurar</v-btn
              >
              <v-btn
                class="ml-5 white--text"
                @click="
                  itemToDelete = item;
                  modalDelete = true;
                "
                small
                color="red darken-4"
                >Eliminar</v-btn
              >
            </template>

            <template v-slot:loading>
              <div class="ma-5" style="position: relative">
                <div
                  style="
                    position: absolute;
                    z-index: 999;
                    width: 100%;
                    height: 100%;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                  "
                >
                  <lottie
                    :options="defaultOptions"
                    :height="300"
                    :width="400"
                    v-on:animCreated="handleAnimation"
                  />
                  <p
                    :class="
                      $vuetify.theme.dark
                        ? 'subtitle-1 primary--text'
                        : 'subtitle-1 black--text'
                    "
                  >
                    Cargando Data
                  </p>
                </div>
                <v-skeleton-loader
                  ref="skeleton"
                  type="table-tbody"
                  class="mx-auto d-none d-md-block"
                ></v-skeleton-loader>
              </div>
            </template>
          </v-data-table>
        </v-container>
      </v-card-text>

    <v-dialog v-if="modalSort" v-model="modalSort" max-width="500px">
      <sort
        :key="`sort-${randomKey}`"
        @cancel="modalSort = false"
        :cityKey="city['.key']"
        @success="getSections"
        :sections="sections"
      >
      </sort>
    </v-dialog>

    <v-dialog scrollable persistent v-if="modalAddSection" v-model="modalAddSection" max-width="800px" >
      <add-section
        :sectionsLength="sections.length"
        @success="handleCreate"
        :sectionSelected="sectionSelected"
        :key="randomKey"
        @cancel="modalAddSection = false"
        :city="city"
      >
      </add-section>
    </v-dialog>

    <v-dialog
      persistent
      scrollable
      v-if="modalConfSection"
      v-model="modalConfSection"
      max-width="90%"
    >
      <conf-section
        :key="randomKey"
        @cancel="modalConfSection = false"
        :city="city"
        :section="sectionSelected"
      >
      </conf-section>
    </v-dialog>

    <v-dialog max-width="400" v-model="modalDelete" v-if="itemToDelete">
      <v-card>
        <v-card-title class="headline"
          >Confirmar acción
          <v-btn
            style="right: 10px; top: 10px"
            icon
            color="primary"
            fab
            absolute
            @click="modalDelete = false"
          >
            <v-icon dark>fa-times</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text>
          ¿Está seguro que desea eliminar
          <span class="font-weight-bold">{{ itemToDelete.name }}</span
          >?
        </v-card-text>

        <v-card-actions style="justify-content: center">
          <v-btn
            color="red darken-4"
            width="90%"
            style="border-radius: 50px"
            class="mb-3"
            dark
            @click="confirmDelete"
          >
            Eliminar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    </v-card>

</template>

<script>
import { db } from "@/firebase";
import Lottie from "../../../../components/Lottie";
import * as animationData from "../../../../assets/ochoColor.json";
import moment from "moment-timezone";
import "moment/locale/es";
moment.locale("es");

import addSection from './add-section'
import confSection from "./configuration/conf-section";
import sort from "./sort";

export default {
  name: "sections",
  props: ["city"],
  components: {
    lottie: Lottie,
    addSection,
    sort,
    confSection,
  },
  data() {
    return {
      animationSpeed: 1.1,
      defaultOptions: {
        animationData: animationData.default,
        loop: true,
        autoplay: true,
      },

      snackbar: false,
      snackbarText: "",
      saving: false,
      loading: true,
      sectionSelected: null,
      modalEditSection: false,
      modalConfSection: false,
      modalSort: false,
      modalAddSection: false,
      modalDelete: false,
      itemToDelete: null,
      search: null,
      sections: [],
      randomKey: 0,
      headers: [
        { text: "Posición", value: "position" },
        { text: "Nombre", value: "name" },
        { text: "Subtítulo", value: "subTitle" },
        { text: "Activo", value: "active", align: "center", justify: "center" },
      
        { value: "options", align: "end", width: "350px" },
      ],
    };
  },
  mounted() {
    this.getSections();
  },

  methods: {
    handleAnimation(anim) {
      this.anim = anim;
      anim.setSpeed(this.animationSpeed);
    },
    getSections() {
      this.sections = [];
      db.collection(`cities/${this.city[".key"]}/sectionsOvive`)
        .where("deleted", "==", false)
        .get()
        .then((response) => {
          response.forEach((item) => {
            let section = item.data();
            section[".key"] = item.id;
            this.sections.push(section);
          });
          this.loading = false;
        });
    },
    handleCreate(result) {
      if (this.sectionSelected)
        this.sections = this.sections.map((item) => {
          return item[".key"] == result[".key"] ? result : item;
        });
      else this.sections.push(result);

      this.modalAddSection = false;
      this.snackbarText = "Información actualizada correctamente.";
      this.snackbar = true;
    },

    confirmDelete() {
      if (this.itemToDelete) {
        db.collection(`cities/${this.city[".key"]}/sectionsOvive`)
          .doc(this.itemToDelete[".key"])
          .update({
            deleted: true,
            active: false,
            deletedAt: new Date(),
            deletedBy: this.$store.state.user[".key"],
          })
          .then(() => {
            this.sections = this.sections.filter(
              (item) => item[".key"] != this.itemToDelete[".key"]
            );
            this.modalDelete = false;
            this.snackbar = true;
            this.snackbarText = "Sección eliminada";
          })
          .catch((error) => {
            this.snackbarText =
              "Ocurrió un error inesperado, inténtelo nuevamente.";
            this.snackbar = true;
          });
      }
    },

    switchControlChanged(data, sectionId, section) {
      if (data && sectionId) {
        this.saving = true;

        db.collection(`cities/${this.city[".key"]}/sectionsOvive`)
          .doc(sectionId)
          .update({
            active: data.active,
          })
          .then((ref) => {
            this.saving = false;
            this.snackbarText = "Información actualizada correctamente.";
            this.snackbar = true;
          })
          .catch((err) => {
            this.saving = false;
            this.snackbarText =
              "Ocurrió un error inesperado, inténtelo nuevamente.";
            this.snackbar = true;

            section.active = !section.active;
          });
      }
    },
    newSection() {
      this.randomKey = Math.random();
      this.sectionSelected = null;
      this.modalAddSection = true;
    },

    editSection(section) {
      this.randomKey = Math.random();
      this.sectionSelected = section;
      this.modalAddSection = true;
    },

    confSection(section) {
      this.randomKey = Math.random();
      this.sectionSelected = section;
      this.modalConfSection = true;
    },
  },
};
</script>
