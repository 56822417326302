<template>
  <v-card>
    <v-snackbar absolute top :timeout="3000" v-model="snackbar">{{
      snackbarText
    }}</v-snackbar>

    <v-overlay class="loading-center" :value="saving">
      <lottie
        :options="defaultOptions"
        :height="150"
        :width="150"
        v-on:animCreated="handleAnimation"
      />
    </v-overlay>
    <v-card-title class="grid-close">
      <span class="headline">
        {{
          selectedItem
            ? "Editar ítem de la sección"
            : "Agregar ítem a la sección"
        }}</span
      >
      <v-btn
        style="right: 10px; top: 10px"
        icon
        color="primary"
        fab
        absolute
        @click="$emit('cancel')"
      >
        <v-icon dark>fa-times</v-icon>
      </v-btn>
    </v-card-title>
    <v-divider></v-divider>
    <v-card-text>
      <v-container fluid>
        <v-row>
          <v-col cols="12" sm="12" md="12">
            <v-row>
              <v-col class="mt-5" cols="5" sm="5" md="5">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <div
                      v-show="imgPreview"
                      @click.prevent="showFileChooser"
                      class="category-image"
                      style="border-radius: 10px"
                    >
                      <img :src="imgPreview" v-on="on" />
                    </div>

                    <div
                      v-on="on"
                      v-show="!imgPreview"
                      @click.prevent="showFileChooser"
                      class="img-container mt-3"
                      style="border-radius: 10px"
                    >
                      <i style="font-size: 30px" class="fas fa-image"></i>
                      <span class="ml-2">
                        {{
                          item.type == "url"
                            ? "Cambiar imagen"
                            : "Vista previa de la imágen"
                        }}</span
                      >
                    </div>
                  </template>
                  <span>{{
                    item.type == "url"
                      ? "Clic para agregar la imagen"
                      : "Imagen del producto o comercio seleccionado"
                  }}</span>
                </v-tooltip>
                <input
                  class="upload"
                  ref="input"
                  type="file"
                  name="image"
                  accept="image/*"
                  @change="imageChanged"
                />
              </v-col>
              <v-col cols="7" sm="7" md="7">
                <v-row>
                  <v-col cols="12" sm="12" md="12">
                    <v-select
                      :items="type"
                      v-model="item.type"
                      class="mt-6"
                      label="Escoge el tipo de ítem"
                      style="border-radius: 10px"
                      item-text="name"
                      @change="setType"
                      item-value="value"
                      filled
                      rounded
                    ></v-select>
                  </v-col>

                  <v-col v-if="item.type == 'url'" cols="12" sm="12" md="12">
                    <v-text-field
                      style="border-radius: 10px"
                      label="Nombre del ítem"
                      filled
                      rounded
                      class="control-input"
                      type="text"
                      placeholder="Ingrese el nombre del item"
                      v-model="item.shortName"
                    />
                  </v-col>

                  <v-col
                    v-if="item.type != 'url' && item.type != 'event'"
                    cols="12"
                    sm="12"
                    md="12"
                  >
                    <v-select
                      label="Escoge una categoría"
                      :items="categories"
                      :loading="loading"
                      v-model="item.category"
                      class="select-city mt-6"
                      @change="getBusinesses"
                      item-text="name"
                      item-value=".key"
                      filled
                      style="border-radius: 10px"
                      rounded
                    ></v-select>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-col>

          <v-col v-if="item.type == 'url'" cols="12" sm="12" md="12">
            <v-text-field
              label="Ingresa una url"
              filled
              style="border-radius: 10px"
              rounded
              type="text"
              placeholder="Ingrese una url"
              v-model="item.url"
            />
          </v-col>

          <v-col
            v-if="
              item.type == 'business' ||
              item.type == 'product' ||
              item.type == 'event'
            "
            cols="12"
            sm="12"
            md="12"
          >
            <v-select
              :items="sortedArrayBusinesses"
              v-model="item.business"
              @change="getProducts"
              label="Escoge un comercio"
              class="select-city mt-6"
              item-text="shortName"
              item-value=".key"
              filled
              style="border-radius: 10px"
              rounded
            ></v-select>
          </v-col>
          <v-col cols="12" sm="12" md="12" v-if="item.type == 'event'">
            <v-select
              :items="event"
              label="Escoge el evento"
              v-model="item.event"
              class="select-city mt-6"
              item-text="name"
              @change="getEvent"
              :item-value="[`.key`]"
              filled
              style="border-radius: 10px"
              rounded
            ></v-select>
          </v-col>
          <v-col v-if="item.type == 'product'" cols="12" sm="12" md="12">
            <v-select
              label="Escoge un Producto"
              :disabled="!item.business"
              :items="sortedArrayProducts"
              v-model="item.product"
              class="select-city mt-6"
              item-text="name"
              @change="productChanged"
              item-value=".key"
              filled
              style="border-radius: 10px"
              rounded
            ></v-select>
          </v-col>
         
        </v-row>
      </v-container>
    </v-card-text>
    <v-divider></v-divider>
    <v-card-actions>
      <v-row class="pa-0 ma-0">
        <v-col cols="6" xs="6" sm="6" md="6">
          <v-row align="center" justify="start">
            <v-switch
              v-model="item.active"
              label="Item de sección activo"
            ></v-switch>
          </v-row>
        </v-col>

        <v-col cols="6" xs="6" sm="6" md="6">
          <v-row align="center" justify="end">
            <v-btn
              v-if="selectedItem"
              @click="updateItem()"
              class="save-btn mt-4"
              color="primary"
              >Guardar</v-btn
            >
            <v-btn
              v-else
              @click="addItem()"
              class="save-btn mt-4"
              color="primary"
              >Guardar</v-btn
            >
          </v-row>
        </v-col>
      </v-row>
    </v-card-actions>
  </v-card>
</template>

<script>
import { db, fb } from "@/firebase";
import Lottie from "../../../../../components/Lottie";
import * as animationData from "../../../../../assets/ochoColor.json";
import { Parser } from "json2csv";
import moment from "moment-timezone";
import "moment/locale/es";
moment.locale("es");
import { mapActions, mapState } from "vuex";

export default {
  name: "edit-section",
  props: ["city", "section", "selectedItem"],
  components: {
    lottie: Lottie,
  },
  data() {
    return {
      animationSpeed: 1.1,
      defaultOptions: {
        animationData: animationData.default,
        loop: true,
        autoplay: true,
      },
      snackbar: false,
      snackbarText: "",
      saving: false,
      categories: [],
      businesses: [],
      event: [],
      business: "",
      imgPreview: "",
      imageFile: "",
      products: [],
      // product : '',
      loading: true,
      item: {
        type: "",
        shortName: "",
        active: false,
        category: "",
        deleted: false,
        product: "",
        father: "fatherId",
        business: "",
        url: "",
        cover: {},
        createdAt: "",
        createdBy: this.$store.state.user[".key"],
      },

      type: [
        { name: "Comercio", value: "business" },
        { name: "Producto", value: "product" },
        { name: "Evento", value: "event" },
        { name: "URL", value: "url" },
      ],
    };
  },

  computed: {
    sortedArrayProducts() {
      function compare(a, b) {
        if (a.name < b.name) return -1;
        if (a.name > b.name) return 1;
        return 0;
      }
      return this.products.sort(compare);
    },
    sortedArrayBusinesses() {
      function compare(a, b) {
        if (a.shortName < b.shortName) return -1;
        if (a.shortName > b.shortName) return 1;
        return 0;
      }
      return this.businesses.sort(compare);
    },
  },

  methods: {
    handleAnimation(anim) {
      this.anim = anim;
      anim.setSpeed(this.animationSpeed);
    },

    showFileChooser() {
      this.$refs.input.click();
    },

    imageChanged(e) {
      if (!e.target.files[0]) return;

      let ref = this;
      this.imageFile = e.target.files[0];

      if (this.imageFile && this.imageFile.type.indexOf("image/") === -1) {
       console.log("tipo de archivo no permitido");
        this.imageFile = null;
      }

      var reader = new FileReader();
      reader.onload = function (e) {
        ref.imgPreview = e.target.result;
      };

      if (this.imageFile) {
        reader.readAsDataURL(this.imageFile);
      } else {
        this.imgPreview = null;
      }
    },

    getBusinesses(category) {
      this.item.product = "";
      this.item.business = "";
      this.$binding(
        "businesses",
        db
          .collection("businesses")
          .where("ochoCategories", "array-contains", category)
      );
    },
    setType(type) {
     // console.debug(type);

      if (type == "event") {
        this.$binding(
          "businesses",
          db
            .collection("businesses")
            .where("manageEvents", "==", true)
            .where("active", "==", true)
        );
       // console.debug(this.businesses);
      }
    },
    async getEvent(event) {
      this.imgPreview =
        this.item.type == "event"
          ? await this.event.find((item) => item[`.key`] == event).cover
              .original
          : "";
      this.item.cover.original = this.imgPreview;
      this.item.cover.loading = this.imgPreview;
    },
    async getProducts(business) {
      if (this.item.type == "business") {
        this.imgPreview =
          this.item.type == "business"
            ? this.businesses.find((item) => item[".key"] == business).cover
                .original
            : "";
      }
      if (this.item.type == "event") {
       // console.debug(business);
        await this.$binding(
          "event",
          db.collection("events").where("business", "==", business)
        );
      }
      this.$binding(
        "products",
        db
          .collection("products")
          .where("business", "array-contains", business)
          .where("deleted", "==", false)
          .where("addon", "==", false)
      );
    },

    productChanged(product) {
      this.imgPreview =
        this.products.find((item) => item[".key"] == this.item.product).images
          .length > 0
          ? this.products.find((item) => item[".key"] == product).images[0]
          : "";
    },

    validate() {
      if (!this.item.type) {
        this.snackbarText = "Debe elegir un tipo de ítem";
        this.snackbar = true;
        return false;
      } else if (this.item.type == "url" && !this.item.shortName) {
        this.snackbarText = "Debe ingresar un nombre";
        this.snackbar = true;
        return false;
      } else if (
        this.item.type == "url" &&
        (!this.item.url || (!this.imageFile && !this.item.cover.original))
      ) {
        this.snackbarText = "Debe ingresar una url y seleccionar una imagen";
        this.snackbar = true;
        return false;
      } else if (this.item.type == "business" && !this.item.business) {
        this.snackbarText = "Debe elegir un comercio";
        this.snackbar = true;
        return false;
      } else if (
        this.item.type == "product" &&
        (!this.item.business || !this.item.product)
      ) {
        this.snackbarText = "Debe elegir un comercio y un producto";
        this.snackbar = true;
        return false;
      } else {
        return true;
      }
    },

    uploadImage() {
      return new Promise((resolve, reject) => {
        //console.log(this.imageFile);
        fb.app()
          .storage(`${process.env.VUE_APP_EL_OCHO_BK}-vive-sections`)
          .ref()
          .child(
            `${this.city[".key"]}/${this.section[".key"]}/${this.item.id}/${this.imageFile.name}`
          )
          .put(this.imageFile)
          .then((res) => {
           // console.debug(res);
            resolve("success");
          })
          .catch((err) => {
            //console.log(err);
            reject(err);
          });
      });
    },
    getPrice() {
      return new Promise(async (resolve, reject) => {
        await db
          .collection("oViveTickets")
          .where("event", "==", this.item.event)
          .orderBy("price", "asc")
          .limit(1)
          .get()
          .then((a) => {
            a.docs.forEach((element) => {
             // console.debug(element.data);
              var datos = element.data();
              resolve(datos.price);
            });
          });
      });
    },
    getSchedule() {
      return new Promise(async (resolve, reject) => {
        // await db
        //   .collection("events").doc(this.item.event)
        //   .get()
        //   .then((a) => {
        //     a.docs.forEach((element) => {
        //      // console.debug(element.data);
        //       var datos = element.data();
        //       resolve(datos.price);
        //     });
        //   });
      });
    },
    async addItem() {
      // console.debug(this.item);
      if (this.validate()) {
        this.saving = true;

        this.item.id = `${Date.now()}-${
          Math.floor(Math.random() * (999999999999 - 99999999)) + 99999999
        }`;

        switch (this.item.type) {
          case "url":
            this.item.url = this.item.url;
            this.item.logo = { original: "", loading: "" };
            break;
          case "business":
            let business = this.businesses.find(
              (item) => item[".key"] == this.item.business
            );
            this.item.logo = business.logo
              ? business.logo
              : { original: "", loading: "" };
            this.item.shortName = business.shortName ? business.shortName : "";
            this.item.cover = business.cover
              ? business.cover
              : { original: "", loading: "" };

            break;
          case "event":
            //product case
            let evento = this.event.find(
              (item) => item[".key"] == this.item.event
            );
            this.item.cover = {
              original: evento.cover ? evento.cover.original : "",
              loading: evento.cover ? evento.cover.loading : "",
            };
            this.item.shortName = evento.name ? evento.name : "";
            this.item.basePrice = parseInt(evento.basePrice) || 0;
            this.item.dateStart = evento.dateStart;
            this.item.variousDates = evento.variousDates || false ;
          
            // this.item.timeEventStart = evento.timeEventStart;

            break;
          case "product":
            let product = this.products.find(
              (item) => item[".key"] == this.item.product
            );
            this.item.cover = {
              original:
                product.images && product.images.length > 0
                  ? product.images[0]
                  : "",
              loading:
                product.images && product.images.length > 0
                  ? product.images[0].replace("original", "loading")
                  : "",
            };
            this.item.shortName = product.name ? product.name : "";
            this.item.logo = { original: "", loading: "" };
            this.item.price = product.price;
            this.item.description = product.description;
            break;

          default:
            this.saving = false;
            this.snackbarText = "Ocurrió un error, inténtelo nuevamente.";
            this.snackbar = true;
            return;
            break;
        }
        this.item.position = this.section.target
          ? this.section.target.length + 1
          : 1;
        this.item.createdAt = new Date();
        const arrayUnion = fb.firestore.FieldValue.arrayUnion;
        db.collection(`cities/${this.city[".key"]}/sectionsOvive`)
          .doc(this.section[".key"])
          .update({
            target: arrayUnion(this.item),
          })
          .then(async (res) => {
            if (this.imageFile) {
              await this.uploadImage();
            }
            this.saving = false;
            if (this.item.business) {
              await db.collection("businesses").doc(this.item.business).update({
                isLobbySection: this.item.active,
              });
            }
            this.$emit("success", this.item);
          })
          .catch((err) => {
            //console.log(err);
            this.saving = false;
            this.snackbarText = "Ocurrió un error, inténtelo nuevamente.";
            this.snackbar = true;
          });
      }
    },

    async updateItem() {
      if (this.validate()) {
        this.saving = true;
        this.item.id = this.item.id
          ? this.item.id
          : `${Date.now()}-${
              Math.floor(Math.random() * (999999999999 - 99999999)) + 99999999
            }`;

        const type = this.item.type;
        const defaulCaseType = this.item.url;
        const caseType = {
          business: () => {
            let business = this.businesses.find(
              (item) => item[".key"] == this.item.business
            );
            this.item.logo = business.logo
              ? business.logo
              : { original: "", loading: "" };
            this.item.cover = business.cover
              ? business.cover
              : { original: "", loading: "" };
            this.item.shortName = business.shortName ? business.shortName : "";
          },
          event: async () => {
            let evento = this.event.find(
              (item) => item[".key"] == this.item.event
            );
            this.item.cover = {
              original: evento.cover ? evento.cover.original : "",
              loading: evento.cover ? evento.cover.loading : "",
            };
            this.item.basePrice = parseInt(evento.basePrice) || "";
            this.item.dateStart = evento.dateStart;
            this.item.shortName = evento.name ? evento.name : "";
            // this.item.timeEventStart = evento.timeEventStart;
          },
          product: () => {
            let product = this.products.find(
              (item) => item[".key"] == this.item.product
            );
            this.item.cover = {
              original:
                product.images && product.images.length > 0
                  ? product.images[0]
                  : "",
              loading:
                product.images && product.images.length > 0
                  ? product.images[0].replace("original", "loading")
                  : "",
            };
            this.item.shortName = product.name ? product.name : "";
            this.item.price = product.price;
            this.item.description = product.description;
          },
        };
        caseType[type] ? caseType[type]() : defaulCaseType;
        let target = this.section.target.map((element) => {
          if (element.position == this.item.position) return this.item;
          else return element;
        });

       // console.debug(target);

        db.collection(`cities/${this.city[".key"]}/sectionsOvive`)
          .doc(this.section[".key"])
          .update({
            target,
          })
          .then(async (ref) => {
            if (this.imageFile) {
             // console.debug("cambio im");
              await this.uploadImage();
            }
            if (this.item.business) {
              db.collection("businesses").doc(this.item.business).update({
                isLobbySection: this.item.active,
              });
            }
            this.$emit("updated", target);
          })
          .catch((error) => {
           console.log(error);
            this.snackbarText =
              "Ocurrió un error inesperado, inténtelo nuevamente.";
            this.snackbar = true;
          });
      }
    },
  },

  async mounted() {
    let categories = this.$binding(
      "categories",
      db
        .collection("categories")
        .where("deleted", "==", false)
        .where("city", "==", this.city[".key"])
    );
    await Promise.all([categories]);

    if (this.selectedItem) {
     // console.debug(this.selectedItem);
      this.item = Object.assign({}, this.selectedItem);
      this.loading = false;

      // this.item.basePrice = db
      //   .collection("oViveTickets")
      //   .where("event", "==", this.event[`.key`]);

      const typeCase = this.item.type;
      const defaulCase = (this.imgPreview = this.item.cover
        ? this.item.cover.original
        : "");
      const cases = {
        business: () => {
          this.$binding(
            "businesses",
            db
              .collection("businesses")
              .where("ochoCategories", "array-contains", this.item.category)
          );
          this.imgPreview = this.item.cover ? this.item.cover.original : "";
        },
        event: () => {
          this.$binding(
            "businesses",
            db.collection("businesses").where("manageEvents", "==", true)
          );
          this.$binding(
            "event",
            db.collection("events").where("business", "==", this.item.business)
          );
          this.imgPreview = this.item.cover ? this.item.cover.original : "";
        },
        product: () => {
          this.$binding(
            "products",
            db
              .collection("products")
              .where("business", "array-contains", this.item.business)
              .where("deleted", "==", false)
              .where("addon", "==", false)
          );
          this.$binding(
            "businesses",
            db
              .collection("businesses")
              .where("ochoCategories", "array-contains", this.item.category)
          );

          this.imgPreview = this.item.cover ? this.item.cover.original : "";
        },
      };
      cases[typeCase] ? cases[typeCase]() : defaulCase;
    } else {
      this.loading = false;
    }
  },
};
</script>

<style scoped>
.category-image {
  max-height: 230px;
  min-height: 230px;
  max-width: 100%;
  min-width: 100%;
  cursor: pointer;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.category-image img {
  max-width: 100%;
  max-height: 230px;
  min-width: 100%;
  min-height: 230px;
  cursor: pointer;
  /* margin-top: 60px; */
  /* border: 1px solid rgba(199, 196, 196, 0.377); */
  border-radius: 10px;
  object-fit: contain;
}

.upload {
  display: none;
}

.img-container {
  max-height: 230px;
  min-height: 230px;
  max-width: 100%;
  min-width: 100%;
  cursor: pointer;
  background-color: #ad9f9f42;
  border: 1px solid #808080b3;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
